/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.12.3-v202503202342-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * The Column Definitions for this Table Definition
 */
export interface ColumnDefinitionOutputV1 {
    /**
     * The column definition's name
     */
    "columnName": string;
    /**
     * The type of the column being defined
     */
    "columnType": ColumnTypeEnum;
    /**
     * The column definition's Unit of Measure
     */
    "columnUom"?: string;
    /**
     * The column definition's optional description
     */
    "description"?: string;
    /**
     * The column definition's ID
     */
    "id": string;
    /**
     * Whether the column is generated by Seeq rather than user created
     */
    "isGenerated": boolean;
    /**
     * Whether the column is hidden
     */
    "isHidden": boolean;
    /**
     * Whether the column is indexed
     */
    "isIndexed": boolean;
    /**
     * The rules associated with the column definition
     */
    "rules": Array<models.ColumnRuleOutputV1>;
    /**
     * Whether to sort ascending
     */
    "sortAscending": boolean;
    /**
     * The sort index for the column, null if not sorted
     */
    "sortIndex"?: number;
}

export enum ColumnTypeEnum {
    UUID = 'UUID' as any,
    TEXT = 'TEXT' as any,
    NUMERIC = 'NUMERIC' as any,
    TIMESTAMPTZ = 'TIMESTAMPTZ' as any,
    BOOLEAN = 'BOOLEAN' as any
}
